@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');

#root {
  user-select: none;
}

html, body {
  background: #222225;
  margin: 0;
  padding: 0;
  color: white;
}

.button {
  font-family: 'Montserrat', sans-serif;
  color: white;
  background: #546c9d;
  border-radius: .6em;
  appearance: none;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  padding: 0.8em 1.8em 0.9em;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;

  &:active {
    background: darken(#546c9d, 10%)
  }
}


.contrast-indicator {
  display: inline-block;
  font-size: 1.5em;
  vertical-align: bottom;
  line-height: 1;
  margin: 0 0 0 .1em;

  color: #ff0000;

  &--match {
    color: rgb(144, 238, 144);
  }
}

.color-picker,
.hue-picker {
  cursor: pointer;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}