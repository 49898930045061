@import url(https://fonts.googleapis.com/css?family=Montserrat:700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{-webkit-user-select:none;-ms-user-select:none;user-select:none}html,body{background:#222225;margin:0;padding:0;color:white}.button{font-family:'Montserrat', sans-serif;color:white;background:#546c9d;border-radius:.6em;-webkit-appearance:none;-moz-appearance:none;appearance:none;border:none;font-weight:bold;font-size:1rem;padding:0.8em 1.8em 0.9em;letter-spacing:.1em;text-transform:uppercase;text-decoration:none;outline:none}.button:active{background:#42557c}.contrast-indicator{display:inline-block;font-size:1.5em;vertical-align:bottom;line-height:1;margin:0 0 0 .1em;color:#ff0000}.contrast-indicator--match{color:#90ee90}.color-picker,.hue-picker{cursor:pointer;cursor:-webkit-grab;cursor:grab}.color-picker:active,.hue-picker:active{cursor:-webkit-grabbing;cursor:grabbing}

